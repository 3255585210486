<template>
  <v-card>
    <v-card-title>
      Nahlášení
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="reports"
      :loading="runningRequest"
      :options.sync="paginationOptions"
      :server-items-length="reportsTotal"
      item-key="id"
      :footer-props="{'items-per-page-options': [10, 20, 50] }"
    >
      <template v-slot:item.reporter.name="{ item }"
      >
        {{item.reporter.firstName}} {{item.reporter.lastName}}
      </template>
      <template v-slot:item.reported.name="{ item }"
      >
        {{item.reported.firstName}} {{item.reported.lastName}}
      </template>
      <template v-slot:item.actions="{ /*item*/ }">
        <v-icon
          small
          @click="deleteDialog = true"
          class="mr-2"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'Reports',
  data () {
    return {
      runningRequest: false,
      headers: [
        {
          text: 'Nahlašovatel',
          value: 'reporter.name'
        },
        {
          text: 'Nahlášený',
          value: 'reported.name'
        },
        {
          text: 'Poptávka/Rezervace',
          value: 'demandId'
        },
        {
          text: 'Typ',
          value: 'reportType.name'
        },
        {
          text: 'Popis',
          value: 'note'
        },
        {
          text: 'Akce',
          value: 'actions',
          sortable: false
        }
      ],
      reports: [],
      reportsTotal: 0,
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      }
    }
  },
  methods: {
    loadReports () {},
    getReports () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get('/admin/reports', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.reports = res.data.reports
          this.reportsTotal = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadReports()
      }
    }
  },
  created () {
    this.getReports()
    this.loadReports = this.debounce(this.getReports, 2000)
  }
}
</script>

<style scoped>

</style>
